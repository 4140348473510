* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Baloo Chettan 2";
  height: 100%;
  margin: 0;
  background: #ffffff;
}

.container {
  width: calc(100% - 342px);
  margin: auto;
}

.wrapper {
  min-height: calc(100vh - 258px);
  background: #f7f9fd;
  padding-bottom: 111px;
}

/* Modal start */
.main-modal {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modal {
  background: #ffffff;
  border: 1px solid #f2eeee;
  -webkit-box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  border-radius: 20px;
  position: relative;
  margin: 20px auto;
  z-index: 50;
  height: 90%;
  overflow: hidden;
  overflow-y: scroll;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000000;
  opacity: 0.5;
}
.modal-cross {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 10px 12px;
}
.modal-cross-icon {
  cursor: pointer;
}
::-webkit-scrollbar {
  display: none;
}

/* Modal end */

/* Header start */
.header {
  background: #ffffff;
  -webkit-box-shadow: 0px 5px 20px 0px rgb(28 31 46 / 10%);
  box-shadow: 0px 5px 20px 0px rgb(28 31 46 / 10%);
  position: relative;
}

.header-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-bar-icon {
  display: none !important;
  font-size: 24px;
  width: 25px;
  height: 25px;
  background: rgba(54, 41, 183, 0.09);
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.header-logo {
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

.header-logo a img {
  height: 50px;

}

.nav-desktop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 38px;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
}

.nav-mobile {
  display: none;
}

.nav-desktop-item {
  text-decoration: none;
  color: #000000;
}
.nav-desktop-item-active {
  text-decoration: none;
  color: #3629b7;
}

/* Header end */

/* Filter start */
.filter-title {
  margin: 89px 0 16px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #07143b;
}

.filter-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 32.06px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.filter-items {
  width: 109.2px;
  height: 113px;
  border-radius: 15px;
  position: relative;
  padding-top: 1px;
}

.filter-items__normal {
  background: rgba(54, 41, 183, 0.09);
  color: #07143b;
  cursor: pointer;
}

.filter-items__1st-grade {
  background: #3629b7;
  color: #07143b;
  cursor: pointer;
}

.filter-items__disable {
  background: rgba(212, 212, 212, 0.2);
  color: #d5d5d5;
}

.filter-year-counter {
  width: 63.11px;
  height: 63px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 50%;
  margin: 12px 23.04px 0px 23.04px;
}

.filter-year-counter__disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.filter-year-number {
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  height: 25px;
  padding-top: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.filter-year-text {
  font-weight: 600;
  font-size: 10px;
  width: 25.05px;
  height: 15px;
  padding: 0px 18.03px 13px 20.04px;
}

.filter-year-grade {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
  text-align: center;
  margin-top: 5.05px;
}

.filter-year-grade__1st {
  color: #ffffff;
}

.filter-year-grade-icon {
  position: absolute;
  width: 73.13px;
  height: 42px;
  left: 36.07px;
  top: 71px;
  color: #f3d5c8;
}

.filter-year-grade-icon-2 {
  position: absolute;
  width: 67.12px;
  height: 36px;
  left: 42.07px;
  top: 77px;
  color: #f3d5c8;
}
/* Filter end */

/* Math start */
.math {
  background: #ffffff;
  border: 1px solid #f2eeee;
  -webkit-box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  margin-bottom: 36px;
  border-radius: 20px;
}

.math-content {
  margin: 19px 38.57px 18px 33.06px;
}

.math-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.math-title {
  font-weight: 900;
  font-size: 20px;
  line-height: 29px;
  color: #494949;
}

.math-description {
  font-weight: 700;
  font-size: 13px;
  line-height: 19px;
  color: #8e8f8f;
  margin-top: 5px;
}

.math-content-expend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 13px;
  cursor: pointer;
}

.math-content-expend-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #7b81a6;
}

.math-main-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 25px;
}

.math-option {
  border: 1px solid #f2eeee;
  -webkit-box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  border-radius: 15px;
  padding: 10px 15px 10px 15px;
  margin-top: 15px;
  width: 215px;
  cursor: pointer;
  position: sticky;
}

.math-option-number {
  width: 33.63px;
  height: 33px;
  background: #f5f4fb;
  border-radius: 50%;
  font-weight: 900;
  font-size: 20px;
  line-height: 29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;  
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #3629b7;
}

.math-option-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #494949;
  margin-top: 4px;
}

.math-option-description {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #7b81a6;
  margin-top: 4px;
}

/* Math Intro start */
.math-intro {
  padding: 66px 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: flex-start;
}
.math-intro-left {
  width: 50%;
  /* margin-right: 20px; */
}
.math-intro-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 43px;
  color: #494949;
}
.math-intro-des {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #8e8f8f;
  white-space: pre-line;
}
.intro-flex-human {
  margin-top: 36px;
}
.math-intro-example {
  margin-top: 36px;
}
.math-intro-example-titile {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #494949;
  margin-bottom: 8px;
}
.math-intro-example-number {
  font-weight: 700;
  font-size: 26px;
  line-height: 37px;
  color: #8e8f8f;
  width: 100px;
  white-space: pre-line;
}
.math-intro-example-number-wrong {
  color: #ff3636;
}
.math-intro-example-example {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.math-intro-example-input {
  border: 1px solid #f7f9fd;
  width: 42px;
  height: 30px;
  -webkit-box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}
.intro-right {
  width: 100%;
  background: #ffffff;
  background-repeat: no-repeat;
  background-position: right;
  padding-bottom: calc(40% * 363 / 428);
  padding-left: 0px;
}
.intro-flex-human-right {
  display: none;
}
.math-intro-btn {
  width: 336px;
  margin: auto;
  cursor: pointer;
  background: #3629b7;
  border-radius: 15px;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  padding: 13px 55px;
  /* margin-top: 110px; */
}
/* Math Intro end */

/* Countdown start */
.math-intro-title__center {
  text-align: center;
}
.count {
  padding: 50px;
}
.count-bg {
  background-position: center;
  background-repeat: no-repeat;
  padding: 116px 200px 116px 200px;
}
.count-start {
  font-weight: 900;
  font-size: 250px;
  line-height: 360px;
  color: #3629b7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
/* Countdown end */

/* Math end */

/* Actual Math start*/
.progress-bar {
  border: 1px solid #808080;
  border-radius: 2px;
  width: 90%;
  height: 46px;
  background: #ffffff;
  margin: 20px auto;
  position: relative;
}
.progress-bar-percent {
  position: absolute;
  top: 10px;
  left: 20px;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  z-index: 10;
  color: #ffffff;
}
.progress-bar-percent-bg {
  position: absolute;
  background: #1b26bb;
  top: 0;
  left: 0;
  height: 46px;
}
.math-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 10vw;
  min-height: calc(100vh - 500px);
}
.math-vertical {
  /* font-size: 100px; */
  min-height: calc(100vh - 500px);
  font-size: 6vw;
}

.math-prompt-text {
  font-size: 4vmin;
  /* min-height: calc(50vh - 500px); */
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 0px 30px 0px 30px;
}

.actual-math {
  font-weight: 700;
  text-align: center;
  color: #494949;
}
.actual-math-border {
  border: 5px solid #000000;
  width: 280px;
  margin: 10px auto;
}
.actual-math-number-end {
  padding-right: 55px;
}
.actual-math-answer {
  background: #f7f9fd;
  -webkit-box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 30%;
  border: 2px solid #efefef;
  color: #3629b7;
  font-weight: 700;
  /* font-size: 100px; */
  font-size: 6vw;
  text-align: center;
}
.actual-math-answer__horizontal {
  font-size: 8vw;
}
.actual-math-answer__desktop {
  display: block;
  margin: 20px auto;
}
.actual-math-answer__mobile {
  display: none;
}
.actual-math-answer-hori-desktop {
  display: block;
}
.actual-math-answer-hori-mobile {
  display: none;
}
.actual-math-answer:focus {
  outline: 0;
}
.actual-math-next-btn {
  width: 446px;
  padding: 13px 0;
  background: #3629b7;
  border-radius: 15px;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  margin: 50px auto 66px auto;
  cursor: pointer;
}
/* Actual Math end*/

/* Score start */
.overview {
  width: calc(100% - 400px);
  margin: auto;
  position: relative;
  min-height: calc(100vh - 400px);
}
.score-score {
  color: #ffffff;
  background-repeat: no-repeat;
  margin: auto;
  width: 181px;
  height: 181px;
  text-align: center;
}
.score-score-number {
  font-weight: 700;
  font-size: 50px;
  padding-top: 50px;
}
.score-score-text {
  font-weight: 600;
  font-size: 18px;
}
.score-score-msg {
  text-align: center;
  margin-top: 20px;
}
.score-score-gb {
  font-weight: 900;
  font-size: 30px;
  color: #494949;
}
.score-score-des {
  font-weight: 700;
  font-size: 18px;
  color: #8e8f8f;
  margin-top: 10px;
}
.overview-score {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  margin-top: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.overview-card {
  border: 1px solid #f2eeee;
  -webkit-box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  border-radius: 15px;
  padding: 30px 10px;
  margin-top: 15px;
  width: 150px;
  text-align: center;
}
.score-completion-number {
  font-weight: 800;
  font-size: 20px;
  color: #bf65df;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 6px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.score-completion-complete {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
  color: #494949;
}
.score__total-number {
  color: #026cdc;
}
.score__total-correct {
  color: #55a55e;
}
.score__total-wrong {
  color: #f75842;
}
.summary-section {
  border: 1px solid #f2eeee;
  -webkit-box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  border-radius: 15px;
  padding: 21px 32px;
  margin-top: 28px;
}

.summary-section-title {
  font-family: "Baloo Chettan 2";
  font-weight: 600;
  font-size: 22px;
}

.summary-answers{
  /* width: 100%; */
  display: flex;
}

.summary-answers-list{
  /* width: -webkit-fill-available; */
  width: 40%;
  margin-left: 28px;
  /* width: 60%; */
}

.summary-answers-chart{
  width: 60%;
}


.short-overview-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  margin-bottom: 5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.short-overview-items-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 14px;
  font-size: 24px;
  line-height: 35px;
  color: #494949;
}
.short-overview-items-check-right-ans {
  font-weight: 700;
  font-size: 16px;
  color: #787878;
}
.short-overview-items-icon {
  margin-top: 8px;
}
.short-overview-items-prompt {
  font-weight: 500;
  font-size: 16px;
  max-width: 400px;
  line-height: normal;
  font-style: italic;
  margin-bottom: 10px;
}

.short-overview-sec-dec {
  font-size: 16px;
  line-height: 23px;
  color: #8e8f8f;
}
.short-overview-sec-dec__number {
  color: #3629b7;
}
.score-doit-btn {
  background: #3629b7;
  border-radius: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  padding: 12px 5px;
  text-align: center;
  width: 335px;
  cursor: pointer;
  margin: 28px auto;
}
/* Score end */

/* How it works start */
.howitworks {
  padding: 65px 55px;
  background: #ffffff;
  border: 1px solid #f2eeee;
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  border-radius: 20px;
  margin-top: 38px;
  overflow-x: hidden;
}
.howitworks-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 43px;
  color: #494949;
}
.howitworks-description {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #8e8f8f;
  margin-top: 9px;
}
.howitworks-guide-description {
  margin-top: 30px;
}
.howitworks-guide {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 50px;
}
.howitworks-container {
  width: 25%;
}
.howitworks-guide-number {
  background: #3629b7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
  width: 29px;
  height: 29px;
  border-radius: 50%;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.howitworks-guide-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #494949;
  margin-top: 10px;
}
.howitworks-guide-long-description {
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #8e8f8f;
  margin-top: 10px;
}
.howitworks-guide-last-description {
  font-size: 18px;
  font-weight: 700;
  margin-top: 58px;
  line-height: 26px;
  color: #8e8f8f;
}
/* How it works end */

/* Statistics page start */
.statistics {
  padding: 65px 55px;
  background: #ffffff;
  border: 1px solid #f2eeee;
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  border-radius: 20px;
  margin-top: 38px;
  overflow-x: hidden;
}
.statistics-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 43px;
  color: #494949;
}
.statistics-description {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #8e8f8f;
  margin-top: 9px;
}
/* Statistics page end */


/* About us start */
.aboutus {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #8e8f8f;
}
.aboutus-readingbook-first {
  padding-top: 10px;
}
.aboutus-readingbook-img {
  width: 400px;
}
.aboutus-readingbook {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}

/* About us end */

/* Contact us start */
.contact {
  background: #ffffff;
  border: 1px solid #f2eeee;
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  border-radius: 20px;
  margin-top: 38px;
  height: 776px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  position: relative;
}
.contact-overlay {
  background: #000000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.contact-container {
  width: 427px;
  margin: auto;
  padding-top: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.contact-title {
  font-weight: 900;
  font-size: 30px;
  line-height: 43px;
  color: #f4f4f4;
}
.contact-description {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #f4f4f4;
}
.contact-form {
  margin-top: 15px;
}
.contact-email {
  background: #f4f4f4;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  padding: 14px 13px;
  border: none;
  border-bottom: 3px solid #3629b7;
  margin-top: 8px;
  letter-spacing: 2px;
  font-family: "Baloo Chettan 2";
}
.contact-email::placeholder {
  color: #c0c0c0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Baloo Chettan 2";
}
.contact-email:focus {
  outline: 0;
}
.contact-message {
  background: #f4f4f4;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  padding: 14px 13px;
  border: none;
  border-bottom: 3px solid #3629b7;
  margin-top: 8px;
  letter-spacing: 2px;
  height: 102px;
  font-family: "Baloo Chettan 2";
}
.contact-message::placeholder {
  color: #c0c0c0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Baloo Chettan 2";
}
.contact-message:focus {
  outline: 0;
}

.contact-submit-btn {
  width: 165px;
  background: #3629b7;
  border-radius: 10px;
  font-weight: 900;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  padding: 15px 17px;
  color: #ffffff;
  margin-top: 28px;
  cursor: pointer;
  border: none;
}
.successmsg {
  background: #ffffff;
  border: 1px solid #f2eeee;
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  border-radius: 20px;
  margin-top: 38px;
  padding: 80px 20px;
  text-align: center;
}
.success-title {
  font-weight: 900;
  font-size: 40px;
  line-height: 58px;
  color: #494949;
  margin-top: 15px;
}
.success-description {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #8e8f8f;
}
.success-goto-home-btn {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  width: 335px;
  padding: 15px 0;
  background: #3629b7;
  border-radius: 15px;
  text-align: center;
  margin: 79px auto 0 auto;
  text-decoration: none;
}
/* Contact us end */

/* Footer start */
footer {
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;
  -webkit-box-shadow: 0px -5px 20px 0px rgb(28 31 46 / 10%);
  box-shadow: 0px -5px 20px 0px rgb(28 31 46 / 10%);
}

.footer-logo {
  margin: 38px 0 7px 0;
}

.footer-flex img {
  height: 25px
}

.footer-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
}
.footer-year {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #8e8f8f;
  margin-bottom: 37px;
}

.footer-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 67px;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: #000000;
}

.footer-menu-item {
  cursor: pointer;
}
/* Footer end */

.hidden {
  display: none;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.previousTimes {
  padding-top: 50px;
  height: 100%;
  width: 100%;
}

.previousTimesResponsive {
  margin : 0 auto;
  height: 600px;
  /* width: 100%; */
}

.previoustimes-tooltip {
  background-color: white;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
}

.header-welcome {
  text-transform: capitalize;
  font-size: 36px;
}

.siteDefaultFont {
  font-family: "Baloo Chettan 2";
}

.intro-previous-times {
  display: block;
  width: 100px;
}

.introduction-previous-activities {
  background: #ffffff;
  border: 1px solid #f2eeee;
  -webkit-box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  box-shadow: 30px 30.24px 20px rgba(0, 0, 0, 0.02),
    0px 12.16px 8.576px rgba(0, 0, 0, 0.013152),
    0px 2.72px 3.712px rgba(0, 0, 0, 0.008304);
  border-radius: 20px;
  /* width: -webkit-fill-available; */
  width: 400px;
  text-align: center;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.introduction-previous-activities-best {
  color: #55A55E;
  font-family: "Baloo Chettan 2";
  font-weight: 600;
  font-size: 24px;
}

.introduction-previous-activities-avg {
  color: #EA985F;
  font-family: "Baloo Chettan 2";
  font-weight: 600;
  font-size: 24px;
}

.introduction-previous-activities-lastdate {
  color: #026CDC;
  font-family: "Baloo Chettan 2";
  font-weight: 600;
  font-size: 24px;
}

.introduction-previous-activities-desc {
  color: #494949;
  font-family: "Baloo Chettan 2";
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.intro-right-human-timer {
  width: 234px;
  height: 236px;
  margin: auto;
  padding-bottom: 40px;
  background-repeat: no-repeat;
}

.introduction-previous-activities-label {
  font-family: "Baloo Chettan 2";
  font-weight: 600;
  font-size: 22px;
}

.intro-right-human {
  width: 500px;
  height: 500px;
  background-repeat: no-repeat;
}

.math-intro-btn-wrapper {
  width: -webkit-fill-available;
  margin-top: 150px;
  /* display: contents; */
}

.ribbon-wrapper {
  width: 54px;
  height: 54px;
  overflow: hidden;
  position: absolute;
  top: -4px;
  right: -4px;
}

.ribbon {
  font: bold 12px sans-serif;
  color: #333;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform:    rotate(45deg);
  -ms-transform:     rotate(45deg);
  -o-transform:      rotate(45deg);
  position: relative;
  padding: 2px 0;
  top: 15px;
  right: 30px;
  width: 132px;
  background-color: #ECA252;
  color: #fff;
  box-shadow: 0 4px 4px rgba(0,0,0,.1);
}

.ribbon-wrapper::before,
.ribbon-wrapper::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 2px solid #B57B43;
}

.ribbon-wrapper::before,
.ribbon-wrapper::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-wrapper::before {
  top: -3;
  left: -3;
}
.ribbon-wrapper::after {
  bottom: 0;
  right: 0;
}